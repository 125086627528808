import * as React from "react";

export class Footer extends React.Component {
  public render() {
    return (
      <footer>
      </footer>
    );
  }
}

import "./navigation.scss";

import * as React from "react";

export const Navigation = () => (
  <nav>
    <div id="links">
      <a href="/">maar.vin</a>
    </div>
  </nav>
);
